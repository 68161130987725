import React from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/SEO';
import SectionIntro from '../../../components/SectionIntro';
import {getImage} from 'gatsby-plugin-image';
import {Link, useStaticQuery, graphql} from 'gatsby';
// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const dataGlossaries = [
  {
    abbr: 'ZSP',
    title: '(Zero Standing Privilege)',
    content: `Whereas to have “standing privilege” means to always have access to a resource (whether they need or are using them), Zero Standing Privilege (ZSP) describes an identity’s state of having none, or zero access to a resource.
Why is this an important concept?  Think of it like this:  if an identity has a standing admin / root access to a production system, that means the entity assuming that identity can perform admin / root operations against that production system.
This is fine when that entity is your IT staff doing their jobs, but is really bad if its a bad actor who has managed to hack their way in.
In the wrong hands, an identity with standing privileged access is a serious threat.  But if an identity has zero standing access, with no access permissions bound to it, it posesses no threat at all.`,
    link: '/learn/glossary/zsp-zero-standing-privilege',
  },
  {
    abbr: 'LPA',
    title: '(Least Privileged Access)',
    content: `Least privilege access (LPA) is the concept of giving a user exactly what they need to do their job access permission-wise – nothing more, and nothing less.

Why is LPA recommended?  With LPA, if an identity is compromised, or if a legitimate user assuming the identity makes a mistake while operating under the identity, the damage that can done with that identity is known and controlled.`,
    link: '/learn/glossary/least-privileged-access',
  },
  {
    abbr: 'JIT',
    title: '(Just In Time)',
    content: `Just-in-time (JIT) access is a concept that has gained traction in cloud computing security circles in recent years. JIT access involves granting users access to cloud environments only when they need it, and revoking that access as soon as it's no longer needed. This approach to cloud access control is based on the principles of just-in-time manufacturing, which emphasizes efficiency and waste reduction by producing only what is needed, when it is needed. Since each cloud environment has its own nuances and criteria, it’s been a challenge for companies to implement JIT easily. 
Building off the previous definitions of ZSP and LPA, a user should remain at ZSP, until they need JIT access to a LPA posture.
`,
    link: '/learn/glossary/just-in-time',
  },
  {
    abbr: 'SCIM',
    title: '(System for Cross-domain Identity Management)',
    content: `System for Cross-domain Identity Management (SCIM) is a collection of application-level protocols that leverage JSON, REST, and diverse authentication methods to automate the process of data provisioning. By adopting SCIM, organizations can effortlessly create, update, or deactivate a wide range of user accounts. SCIM facilitates the transfer of essential information from the identity provider to the application, ensuring seamless sign-in and sign-out experiences for users across multiple applications they require.`,
    link: '/learn/glossary/scim-system-for-cross-domain-identity-management',
  },
  {
    abbr: 'IDP',
    title: '(Identity Provider)',
    content: `An identity provider (IDP) is responsible for storing and overseeing the digital identities of users. It can be compared to a guest list for digital and cloud-based applications, rather than a physical event. The IDP verifies user identities by means of username-password combinations and other factors, or it can simply provide a list of user identities for another service provider (such as a Single Sign-On) to verify.
`,
    link: '/learn/glossary/idp-identity-provider',
  },
  {
    abbr: 'SP',
    title: '(Service Provider)',
    content: `A service provider is a third-party company that provides scalable computing resources that businesses can access on demand over a network, including but not limited to cloud-based compute, storage, platform, and application services.`,
    link: '/learn/glossary/sp-service-provider',
  },
  {
    abbr: 'SAML',
    title: '(Security Assertion Markup Language)',
    content: `SAML is an open standard for exchanging authentication and authorization across three entities, the human user trying to login to a website or service, an Identity Provider (which contains the user’s username and password), and the Service Provider (which contains the target website or service the user is logging into.)`,
    link: '/learn/glossary/saml-security-assertion-markup-language',
  },
  {
    abbr: 'ITDR',
    title: '(Identity Detection and Response)',
    content: `Identity threat detection and response (ITDR) is a security procedure for identifying, reducing, and responding to potential identity-based threats, such as compromised user accounts, leaked passwords, data breaches, and fraudulent activity. The primary aim of ITDR is to provide continuous visibility and control over identities' privileges and activities, ensuring they align with the principle of least privilege and zero standing access.`,
    link: '/learn/glossary/itdr-identity-threat-detection-and-response',
  },
  {
    abbr: 'RBAC',
    title: '(Role-based Access Control)',
    content: `Role-based access control (RBAC) is a security model that restricts access to computer resources based on the roles assigned to individual users within an organization. An RBAC system grants permissions based on the user's job function or title, rather than the individual's identity.`,
    link: '/learn/glossary/rbac-role-based-access-control',
  },
  {
    abbr: 'ABAC',
    title: '(Attribute-based Access Control)',
    content: `Attribute-based access control (ABAC) is a method of restricting access to resources based on attributes associated with the user or the resource being accessed. ABAC is a popular approach to access control in cloud-based applications because it offers granular control over access permissions, making it possible to enforce fine-grained policies`,
    link: '/learn/glossary/abac-attribute-based-access-control',
  },
  {
    abbr: 'PAM',
    title: '(Privileged Access Management)',
    content: `Privileged access management refers to the management and control of privileged accounts and their associated access rights. This includes the identification and classification of privileged accounts, the enforcement of least privilege principles, and the monitoring of privileged activity to detect and respond to potential threats.`,
    link: '/learn/glossary/pam-privileged-access-management',
  },
  {
    abbr: 'DevSecOps',
    title: '(Development, Security, and Operations)',
    content: `Short for development, security, and operations – is the practice of integrating security continuously throughout the software and application development lifecycle to ensure optimal security and performance efficiency.It is considered a necessary extension of the DevOps methodology.`,
    link: '/learn/glossary/devsecops-development-security-and-operations',
  },
  {
    abbr: 'Zero Trust',
    title: '',
    content: `Zero Trust is a security concept that requires all users to be authenticated and authorized before being granted access to applications, resources and data.`,
    link: '/learn/glossary/zero-trust',
  },
  {
    abbr: 'IAM',
    title: '(Identity Access Management)',
    content: `Identity and access management (IAM) is a framework that allows the IT team to control access to systems, networks and assets based on each user’s identity.`,
    link: '/learn/glossary/iam-identity-access-management',
  },
  {
    abbr: 'Workflow orchestration',
    title: '',
    content: `Cloud workflow orchestration involves the coordination of various tools, applications, APIs, and infrastructure within private and public clouds to create cohesive workflows and automation. With the help of Trustle,SOC and IT teams can streamline the automation of cloud management tasks across different teams and domains.`,
    link: '/learn/glossary/workflow-orchestration',
  },
  {
    abbr: 'IGA',
    title: '(Identity Governance and Administration)',
    content: `Identity Governance and Administration (IGA) empowers security administrators to effectively oversee user identities and access throughout the organization. It enhances their ability to monitor identities and access privileges, enabling them to implement the required controls to mitigate the risk of unauthorized or hazardous access.`,
    link: '/learn/glossary/iga-identity-governance-and-administration',
  },
  {
    abbr: 'UEBA',
    title: '(User and Entity Behavior Analytics)',
    content: `A technology that analyzes user and entity behavior to identify anomalies that may indicate a threat. UEBA can be used to detect threats that are not easily detected by traditional security tools.`,
    link: '/learn/glossary/ueba-user-and-entity-behavior-analytics',
  },
  {
    abbr: 'Threat intelligence',
    title: '',
    content: `Information about known threats, such as malware signatures and attack vectors. Threat intelligence can be used to improve the effectiveness of ITDR by helping to identify and respond to threats more quickly.`,
    link: '/learn/glossary/threat-intelligence',
  },
  {
    abbr: 'Cyber hygiene',
    title: '',
    content: `The practices and procedures that an organization follows to protect its systems and data from cyber threats. Cyber hygiene includes things like regularly updating software, using strong passwords, and being careful about what links you click on.`,
    link: '/learn/glossary/cyber-hygiene',
  },
  {
    abbr: 'PBAC',
    title: '(Policy-Based Access Control)',
    content: `Policy-Based Access Control (PBAC) is an alternative access management approach centered around authorization. In contrast to RBAC, which limits user access using fixed roles, PBAC dynamically determines access privileges through rules and policies. While PBAC shares similarities with ABAC (Attribute-Based Access Control), it is less resource-intensive to implement. ABAC often demands additional IT and development resources, such as XML coding, especially when dealing with a larger number of attributes.`,
    link: '/learn/glossary/pbac-policy-based-access-control',
  },
  {
    abbr: 'Identity Attack Surface',
    title: '',
    content: `An attack surface refers to the entirety of potential entry points through which unauthorized access to a system can be attempted. It encompasses all vulnerabilities, weaknesses, and endpoints that could potentially be exploited by malicious actors to launch security attacks.`,
    link: '/learn/glossary/identity-attack-surface',
  },
  {
    abbr: 'CIEM',
    title: '(Cloud Infrastructure Entitlement Management)',
    content: `Cloud Infrastructure Entitlement Management (CIEM) refers to the management of identities and privileges within cloud environments. Its primary objective is to comprehensively assess access entitlements in both cloud and multi cloud settings. By doing so, CIEM aims to pinpoint and address risks arising from entitlements that provide greater access levels than necessary.`,
    link: '/learn/glossary/ciem-cloud-infrastructure-entitlement-management',
  },
  {
    abbr: 'Orphaned Account',
    title: '',
    content: `Orphaned accounts are user accounts that have been deserted by their owners or are no longer associated with active users within the company. These accounts can be found in various applications, infrastructure, or systems used for business operations. An account is considered orphaned if there is no currently active human user responsible for its use or if it is not actively utilized.`,
    link: '/learn/glossary/orphaned-account',
  },
  {
    abbr: 'Dormant Account',
    title: '',
    content: `A dormant account is an identity which exists, but has not been used for a period of time.  The period of time required to quality an account as dormant may vary.  For example, as you may only login to the DMV twice a year at most, the DMV could define a dormant account being “an account that hasn’t been logged into for more than a year.”`,
    link: '/learn/glossary/dormant-account',
  },
  {
    abbr: 'Privileged Account',
    title: '',
    content: `A privileged account is any account which carries more privileges than a standard user account.
For example, a standard user account may enable someone to login and send an email under their name.  But a privileged account may allow for not only email sending, but also user administration, user billing inforomation access, and users’ private profile information such `,
    link: '/learn/glossary/privileged-account',
  },
  {
    abbr: 'Standing Access',
    title: '',
    content: `Standing access pertains to the continuous and unimpeded access to systems or resources, even when not
        immediately necessary. This form of access is commonly extended to privileged users like system administrators,
        and at times regular users for specific functions`,
    link: '/learn/glossary/standing-access',
  },
  {
    abbr: 'UUR',
    title: '(Under Utilization Rate)',
    content: `The term "under utilization rate" refers to a metric which determines to what extent a permission is being
        utilized less than expected or anticipated. In other words, it signifies that certain software-as-a-service
        (SaaS) applications or user permissions are not being used as much as they could or should be.`,
    link: '/learn/glossary/under-utilization-rate',
  },
  {
    abbr: 'PUR',
    title: '(Permission Utilization Rate)',
    content: `PUR stands for Permission Utilization Rate. As the opposite of UUR, it is the percentage of granted permissions which are actually utilized by the principal (application or user).`,
    link: '/learn/glossary/permission-utilization-rate',
  },
  {
    abbr: 'DSPM',
    title: '(Data Security Posture Management)',
    content: `DSPM stands for "Data Security Posture Management." It is a term to describe the practice of assessing, managing, and improving an organization's overall data security posture. A data security posture refers to the collective security measures, strategies, policies, and practices that an organization employs to protect its data assets from various threats and vulnerabilities.`,
    link: '/learn/glossary/data-security-posture-management',
  },
];

const CardGlossary = ({abbr, title, link, content, bgGlossaryCard}) => {
  const contentCard = (
    <>
      <div className="cursor-pointer transition duration-300 ease-in-out rounded-3xl group hover:shadow-xl  shadow-md w-full lg:w-[416px] lg:h-[416px] mx-auto bg-[#F8F9FC]">
        <BackgroundImage
          Tag="section"
          // Spread bgImage into BackgroundImage:
          {...bgGlossaryCard}
          preserveStackingContext
          className="z-10 h-40 overflow-hidden bg-top shadow-md rounded-t-3xl"
        >
          <div className="flex flex-col items-center justify-center h-40 px-4 py-6 text-lg md:text-1xl text-blue md:px-8 ">
            {abbr && <div className="text-2xl font-bold text-center text-white md:text-3xl">{abbr}</div>}
            <div className="text-xl font-bold text-center text-white md:text-2xl">{title}</div>
          </div>
        </BackgroundImage>
        <div className="px-6 py-4 text-lg font-normal text-black text-opacity-60 ">
          <p className="text-lg line-clamp-6">{content}</p>
        </div>
        <div className="px-6 pb-4 ">
          <span className="text-lg font-semibold hover:underline text-light-blue">Read More {`>`}</span>
        </div>
      </div>
    </>
  );
  return (
    <>
      <Link to={link} partiallyActive={true}>
        {contentCard}
      </Link>
    </>
  );
};

const Glossary = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderBgGlossary: file(relativePath: {eq: "bg/bg-glossary-card.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      backgroundImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderBgGlossary, backgroundImage} = dataImages;
  const bgGlossaryCard = convertToBgImage(getImage(placeholderBgGlossary));
  const bgImage = convertToBgImage(getImage(backgroundImage));

  return (
    <Layout
      seo={
        <Seo title="Glossary | Trustle" description="Get acquainted with the most common used terms and definitions." />
      }
    >
      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext className="relative top-0">
        <section className="pb-16 xl:pb-64">
          <div className="flex flex-col py-16 lg:pb-32 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro
              isPageHeader
              hero="Learn"
              title="Glossary"
              description={`Get acquainted with the most common used terms and definitions.`}
            />
            <div className="max-w-[1310px] grid grid-cols-1 gap-8 mx-auto md:grid-cols-2 xl:grid-cols-3">
              {dataGlossaries.map((glossary, index) => (
                <CardGlossary
                  abbr={glossary.abbr}
                  title={glossary.title}
                  link={glossary.link}
                  content={glossary.content}
                  bgGlossaryCard={bgGlossaryCard}
                  key={`${index}-${glossary.title}`}
                />
              ))}
            </div>
          </div>
        </section>
      </BackgroundImage>
    </Layout>
  );
};

export default Glossary;
